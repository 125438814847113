import styled from "styled-components";
import { IconProps } from "../../types";

const ImgRound = styled.img`
  border-radius: 50%;
    border: 1px solid #ffffff;
    overflow: hidden;
    margin-right: 10px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    object-fit: cover;
`
export const SvgIcon = ({ src, width, height, round }: IconProps) => (
  round?<ImgRound src={`/img/svg/${src}`}/>:<img src={`/img/svg/${src}`} alt={src} width={width} height={height}/>
);
