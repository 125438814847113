import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ThemeProps } from "../../theme";

export const HeaderSection = styled("header")<ThemeProps>`
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 999;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #fff9;
`;

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
    margin-top:20px;
  }

  display: none;

  svg {
    fill: #2e186a;
  }
`;

export const NotHidden = styled("div")`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const CustomNavLinkSmall = styled("div")`
  display: inline-block;
  text-align: center;
  font-size: 1.2rem;
  color: #18216d;
  transition: color 0.2s ease-in;
  margin: 0.5rem 1rem;

  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: ${(props) => props.theme.fonts.light};

  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 130, 92);
    text-underline-position: under;
    text-decoration: rgb(255, 130, 92) wavy underline;
  }

  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
`;
export const RssNewsTitle = styled.a`
  margin: 10px;
  &:hover {
    color: rgb(255, 130, 92);
    text-underline-position: under;
    text-decoration: rgb(255, 130, 92) wavy underline;
  }
`;
export const RssNewsHeader = styled.div`
  font-family: ${(props) => props.theme.fonts.light};
  background-color: ${props=>props.theme.colors.primary};
  padding: 5px;
  @media only screen and (max-width: 768px) {
    padding-left: 5px;
  }
  height:24px;
  position: fixed;
  padding-left: 30px;
  padding-right: 45px;
  display: flex;
  align-items: center;
  z-index: 99;
  clip-path: polygon(0px 0px, 100% 0%, 86% 100%, 0% 100%);
  color: ${props=>props.theme.colors.secondary};
`;

