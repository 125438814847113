import { Col, Row } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { Icon } from "../../common/Icon";

import { Link } from "react-router-dom";
import {
  Extra,
  FooterContainer,
  FooterSection,
  Para,
  SmallText
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <Icon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row style={{ paddingBottom: "1rem" }}>
            <Icon src="ethetf-logo.svg" height="50px" width="100%" />
          </Row>
          <Row justify="center" gutter={[40, 40]}>
            <Col>
              <Para>
                <Link to="terms">{t("Terms")}</Link>
              </Para>
            </Col>
            <Col>
              <Para>
                <Link to="privacy">{t("Privacy")}</Link>
              </Para>
            </Col>
            <Col>
              <Para>
                <Link to="cookies">{t("Cookies")}</Link>
              </Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true} >
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
            gutter={[20,10]}
          >
            <Col md="15"  xs="24">
              <SmallText>
                {t("copyright-2023-ether-etf-token-all-rights-reserved")}{" "}
              </SmallText>
              <SmallText>
                {t(
                  "disclaimer-cryptocurrency-may-be-unregulated-in-your-jurisdiction-the-value-of-cryptocurrencies-may-go-down-as-well-as-up-profits-may-be-subject-to-capital-gains-or-other-taxes-applicable-in-your-jurisdiction"
                )}
              </SmallText>
            </Col>
            <Col md="3" xs="12">
              <SmallText>Ether ETF Ltd.</SmallText>
              <SmallText>36 Carpenter Street,</SmallText>
            </Col>
            <Col md="3" xs="12">
              <SmallText>Singapore, 069885</SmallText>
              <SmallText>VAT: 602692452</SmallText>
            </Col>
            <Col md="3" sm="24">
              <FooterContainer>
                <SocialLink
                  href="https://github.com/etheretf"
                  src="github.svg"
                />
                <SocialLink
                  href="https://twitter.com/etheretf"
                  src="svg-icons/x.svg"
                />
                <SocialLink
                  href="https://t.me/etheretf"
                  src="svg-icons/telegram.svg"
                />
              </FooterContainer>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
