import { IconProps } from "../../types";
import { Player } from "@lottiefiles/react-lottie-player";
import "@dotlottie/player-component";

export const LottieIcon = ({ src, width, height }: IconProps) =>
  src.endsWith(".json") ? (
    <Player
      src={`/img/lottie/${src}`}
      loop
      autoplay
      style={{ height, width }}
    />
  ) : (
    <dotlottie-player
      autoplay
      loop
      mode="normal"
      src={`/img/lottie/${src}`}
      style={{ height, width }}
    ></dotlottie-player>
  );
