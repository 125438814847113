import { Dropdown, Space, MenuProps, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { Icon } from "../Icon";
import i18n from "i18next";
import React from "react";

const countryArray = [
  { title: "العربية", code: "ar" },
  { title: "България", code: "bg" },
  { title: "中国", code: "cn" },
  { title: "Česká republika", code: "cz" },
  { title: "Deutschland", code: "de" },
  { title: "Ελλάδα", code: "el" },
  { title: "United Kingdom", code: "en" },
  { title: "España", code: "es" },
  { title: "ایران", code: "fa" },
  { title: "France", code: "fr" },
  { title: "Magyarország", code: "hu" },
  { title: "Indonesia", code: "id" },
  { title: "Italia", code: "it" },
  { title: "日本", code: "jp" },
  { title: "한국", code: "kr" },
  { title: "Nederland", code: "nl" },
  { title: "Polska", code: "pl" },
  { title: "Portugal", code: "pt" },
  { title: "România", code: "ro" },
  { title: "Россия", code: "ru" },
  { title: "Slovensko", code: "sk" },
  { title: "ประเทศไทย", code: "th" },
  { title: "Türkiye", code: "tr" },
  { title: "Việt Nam", code: "vn" },
];

const items: MenuProps["items"] = countryArray.map<ItemType>(
  (country, index) => ({
    key: index,
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => i18n.changeLanguage(country.code)}
      >
        <Icon
          round
          src={"flags/" + country.code + ".svg"}
          width="64px"
          height="64px"
        />{" "}
        {country.title}
      </a>
    ),
  })
);
const menuStyle: React.CSSProperties = {
  boxShadow: "none",
  maxHeight: "200px",
  overflowY: "auto",
};

export const LangSeletor = () => (
  <Dropdown
    menu={{ items }}
    dropdownRender={(menu) => (
      <div>
        {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
      </div>
    )}
  >
    <a onClick={(e) => e.preventDefault()}>
      <Space>
        {i18n.language.toUpperCase()}
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
);
