export const theme = {
    colors:{
        primary: "#18216D",
        secondary: "#ff825c",
    },
    
    fg: "#BF4F74",
    bg: "white",
    fonts:{
        light: "Poppins Light",
        regular: "Poppins Regular",
        bold: "Poppins Bold",
    }
  };

export type ThemeProps = {
    theme:typeof theme
}