import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import Router from "./router";
import i18n from "./translation";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";

import { WagmiConfig } from "wagmi";
import { sepolia, mainnet } from "viem/chains";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import { ConfigProvider } from "antd";

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "2f0b3474262833aa0adc9f2e5128798c";

// 2. Create wagmiConfig
const metadata = {
  name: "Etherum ETF",
  description: "Ethereum ETF solution",
  url: "https://etheretf.network",
  icons: ["https://etheretf.network/icon"],
};

const chains = [mainnet, sepolia];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "light",
  themeVariables: {
    "--w3m-accent": "#18216d",
    "--w3m-font-family":"Poppins Light"
  },
});

const App = () => (
  <WagmiConfig config={wagmiConfig}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "Poppins Light"
              },
            }}
          >
            <Router />
          </ConfigProvider>
        </ThemeProvider>
      </I18nextProvider>
    </BrowserRouter>
  </WagmiConfig>
);

ReactDOM.render(<App />, document.getElementById("root"));
