import { Col, Drawer, Row } from "antd";
import { useState } from "react";
import Marquee from "react-fast-marquee";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Container from "../../common/Container";
import { Icon } from "../../common/Icon";
import { LangSeletor } from "../../common/LangSelector";
import {
  Burger,
  CustomNavLinkSmall,
  HeaderSection,
  Label,
  LogoContainer,
  Menu,
  NotHidden,
  Outline,
  RssNewsHeader,
  RssNewsTitle,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const history = useHistory();
  const rssNews = [
    {
      title: t('ethereum-etf-how-blackrocks-proposal-affects-cryptos-outlook'),
      link:"https://money.usnews.com/investing/cryptocurrency/articles/ethereum-etf-how-blackrocks-proposal-affects-cryptos-outlook"
    },
    {
      title: t("ethereum-etfs-the-next-step-in-defi-adoption-0"),
      link: "https://consensys.io/blog/ethereum-etfs-the-next-step-in-defi-adoption",
    },
    {
      title: t("hashdex-nasdaq-ethereum-etf"),
      link: "https://hashdex.com/en-KY/products/hethbh/",
    },
    {
      title: t("best-ethereum-etfs-to-watch-in-2023"),
      link: "https://www.kucoin.com/learn/crypto/best-ethereum-etfs-to-watch",
    },
    {
      title: t("the-best-ethereum-etfs-etns"),
      link: "https://www.justetf.com/en/how-to/invest-in-ethereum.html",
    },
    {
      title: t(
        "why-is-grayscales-filing-for-an-ethereum-futures-etf-a-big-deal"
      ),
      link: "https://www.techopedia.com/why-is-grayscales-filing-for-an-ethereum-futures-etf-a-big-deal",
    },
  ];
  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    // const scrollTo = (id: string) => {
    //   const element = document.getElementById(id) as HTMLDivElement;
    //   element.scrollIntoView({
    //     behavior: "smooth",
    //   });
    //   setVisibility(false);
    // };
    const scrollTo = (selector: string, offset: number = 100) => {
      console.log(history.location);
      if (history.location.pathname != "/") history.push("/");
      setTimeout(() => {
        let element = document.getElementById(selector);
        if (element) {
          window.scrollTo({
            behavior: "smooth",
            top:
              element.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              offset,
          });
        }
        setVisibility(false);
      }, 100);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          {t("About")}
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("howtobuy")}>
          {t("How to buy")}
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("roadmap")}>
          {t("Roadmap")}
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => history.push("/staking")}>
          {t("Staking")}
        </CustomNavLinkSmall>
        <w3m-button />
        <CustomNavLinkSmall>
          <LangSeletor />
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <Icon src="ethetf-logo.svg" width="200px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>

        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>{t("Menu")}</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
      <Row>
          <RssNewsHeader>Ether ETF News</RssNewsHeader>
          <Marquee pauseOnHover gradient>
            {rssNews.map((e, i) => (
              <RssNewsTitle key={i} href={e.link}>{e.title}</RssNewsTitle>
            ))}
          </Marquee>
      </Row>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
